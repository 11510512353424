import { OptionsObject, useSnackbar, VariantType } from 'notistack'

export const useNotifications = () => {
  const { enqueueSnackbar } = useSnackbar()

  const defaultNotificationConfig: OptionsObject = {
    autoHideDuration: 5000
  }

  const makeShow = (variant: VariantType) => (message: string) => enqueueSnackbar(message, {
    ...defaultNotificationConfig,
    variant
  })

  return {
    showError: makeShow('error'),
    showInfo: makeShow('info')
  }
}
