import React, { useContext } from 'react'

import { Button } from '@mui/material'
import { useNotifications } from '../../utils/useNotifications'
import { Article, jsonArticlesC } from '../../utils/codecs'
import { useMutation, useQueryClient } from 'react-query'
import AppContext from '../AppContext'
import handleJsonUpload from '../../utils/handleJsonUpload'

const InventoryUploadButton = () => {
  const queryClient = useQueryClient()
  const { http } = useContext(AppContext)
  const { showError } = useNotifications()

  const { mutate } = useMutation(
    (article: Article) => http.post('/inventory', article),
    {
      onError: err => {
        showError((err as Error).message)
      },
      onSettled: () => {
        queryClient.invalidateQueries('inventory')
      }
    }
  )

  const handleChange = handleJsonUpload(
    showError,
    jsonArticlesC,
    ({ inventory }) => inventory.map(article => mutate(article))
  )

  return (
        <label htmlFor="upload-inventory">
            <input
                style={{ display: 'none' }}
                id="upload-inventory"
                name="upload-inventory"
                type="file"
                accept="application/json"
                onChange={handleChange}
            />

            <Button color="primary" variant="contained" component="span">
                Upload Inventory
            </Button>
        </label>
  )
}

export default InventoryUploadButton
