import React, { ChangeEventHandler, useContext, useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import AppContext from '../AppContext'
import { useNotifications } from '../../utils/useNotifications'
import { ProductSummary } from '../../utils/codecs'
import { Button, TextField } from '@mui/material'

type Props = {
    product: ProductSummary
}

const ProductSell = ({ product }: Props) => {
  const [amount, setAmount] = useState(0)

  const queryClient = useQueryClient()
  const { http } = useContext(AppContext)
  const { showError } = useNotifications()

  const { mutate: sell } = useMutation(
    (amount: number) => http.post(`/products/${product.id}/sell`, { amount }),
    {
      onError: err => {
        showError((err as Error).message)
      },
      onSettled: () => {
        queryClient.invalidateQueries('products')
        setAmount(0)
      }
    }
  )

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const value = parseInt(event.target.value)
    if (value >= 0 && value <= product.availability) setAmount(value)
  }

  return (
        <>
            <TextField
                id="standard-number"
                label="Amount"
                type="number"
                size="small"
                value={amount}
                onChange={handleChange}
                variant="standard"
                style={{
                  marginRight: '5px'
                }}
            />
            <Button color="primary" variant="contained" onClick={() => sell(amount)}>sell</Button>
        </>
  )
}

export default ProductSell
