import { ChangeEventHandler } from 'react'
import { pipe } from 'fp-ts/function'
import * as J from 'fp-ts/Json'
import * as E from 'fp-ts/Either'
import { Decoder } from 'io-ts'

const makeHandler = <B>(
  onError: (err: string) => void,
  decoder: Decoder<unknown, B>,
  onSuccess: (b: B) => any
): ChangeEventHandler<HTMLInputElement> => event => {
    const files = event?.target.files

    if (!files) return onError('There is no file to parse')
    const file = files[0]
    const reader = new FileReader()

    reader.addEventListener('load', () => {
      if (!reader.result) return onError('Unable to load the file')
      pipe(
        J.parse(reader.result.toString()),
        E.chainW(result => decoder.decode(result)),
        E.map(onSuccess),
        E.mapLeft(() => onError('something went wrong while reading the file'))
      )
    })

    reader.readAsText(file, 'utf-8')
  }

export default makeHandler
