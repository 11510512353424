import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import CssBaseline from '@mui/material/CssBaseline'
import loadConfig from './config'
import { pipe } from 'fp-ts/function'
import * as E from 'fp-ts/Either'

pipe(
  loadConfig(),
  E.fold(
    () => (
            <React.StrictMode>
                <div>an error occurred while trying to load the application</div>
            </React.StrictMode>
    ),
    appConfig => (
            <React.StrictMode>
                <CssBaseline/>
                <App appConfig={appConfig}/>
            </React.StrictMode>
    )
  ),
  app => {
    ReactDOM.render(app, document.getElementById('root'))
  }
)
