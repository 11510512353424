import React, { ChangeEventHandler, useContext, useState } from 'react'
import { Article } from '../../utils/codecs'
import { useMutation, useQueryClient } from 'react-query'
import AppContext from '../AppContext'
import { useNotifications } from '../../utils/useNotifications'
import { Button, TextField } from '@mui/material'

type Props = {
    article: Article
}

const UpdateStock = ({ article }: Props) => {
  const [amount, setAmount] = useState(0)

  const queryClient = useQueryClient()
  const { http } = useContext(AppContext)
  const { showError } = useNotifications()

    enum Action {
        Remove = -1,
        Add = 1
    }

    const { mutate: restock } = useMutation(
      ({ amount, type }: { amount: number, type: Action }) =>
        http.post(`/inventory/${article.art_id}`, { stock: article.stock + Math.sign(type) * amount }),
      {
        onError: err => {
          showError((err as Error).message)
        },
        onSettled: () => {
          queryClient.invalidateQueries('inventory')
          setAmount(0)
        }
      }
    )

    const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
      const value = parseInt(event.target.value)
      if (value >= 0) setAmount(value)
    }

    return (
        <>
            <TextField
                id="standard-number"
                label="Amount"
                type="number"
                size="small"
                value={amount}
                onChange={handleChange}
                variant="standard"
                style={{
                  marginRight: '5px'
                }}
            />
            <Button
                color="secondary"
                variant="contained"
                onClick={() => restock({ amount, type: Action.Remove })}
                style={{
                  marginRight: '5px'
                }}
            >
                remove
            </Button>
            <Button
                color="primary"
                variant="contained"
                onClick={() => restock({ amount, type: Action.Add })}
            >
                add
            </Button>
        </>
    )
}

export default UpdateStock
