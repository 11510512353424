import React from 'react'
import {
  styled,
  ThemeProvider,
  createTheme, Container
} from '@mui/material'
import JAppBar from './components/JAppBar'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import Inventory from './views/Inventory'
import { QueryClient, QueryClientProvider } from 'react-query'
import { AppConfig } from './config'
import axios from 'axios'
import AppContext from './components/AppContext'
import { SnackbarProvider } from 'notistack'
import Products from './views/Products'

const queryClient = new QueryClient()

const Wrapper = styled('div')(() => ({
  width: '100vw',
  height: '100vh',
  margin: 'auto',
  backgroundColor: '#7e7e7e'
}))

type Props = { appConfig: AppConfig }

const app = ({ appConfig }: Props) => {
  const mainTheme = createTheme({
    palette: {
      mode: 'dark'
    }
  })

  const http = axios.create({
    baseURL: appConfig.backendHost
  })

  return (
        <AppContext.Provider value={{ http, backendHost: appConfig.backendHost }}>
            <QueryClientProvider client={queryClient}>
                <ThemeProvider theme={mainTheme}>
                    <SnackbarProvider>
                        <Router>
                            <Wrapper>
                                <JAppBar/>
                                <main>
                                    <Container>
                                        <Route path='/' exact>
                                            <Inventory/>
                                        </Route>
                                        <Route path='/products'>
                                            <Products/>
                                        </Route>
                                    </Container>
                                </main>
                            </Wrapper>
                        </Router>
                    </SnackbarProvider>
                </ThemeProvider>
            </QueryClientProvider>
        </AppContext.Provider>
  )
}

export default app
