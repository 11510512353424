import React, { useContext } from 'react'
import { AppBar, Button, styled, Toolbar, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import AppContext from './AppContext'

const MainBar = styled(AppBar)(({ theme }) => ({
  marginBottom: theme.spacing(2)
}))

const Logo = styled(Typography)(({ theme }) => ({
  marginRight: theme.spacing(1),
  color: 'inherit'
}))

const JAppBar = () => {
  const { backendHost } = useContext(AppContext)

  return (
        <MainBar position="static">
            <Toolbar variant="dense">
                <Logo variant="h6">
                    Dorr
                </Logo>
                <Button component={Link} to="/">
                    Inventory
                </Button>
                <Button component={Link} to="/products">
                    Products
                </Button>

                <div style={{
                  flexGrow: 1
                }}/>

                <Button href={`${backendHost}/docs`} target="_blank">
                    Documentation
                </Button>

                <Button href={`${backendHost}`} target="_blank">
                    Lager API
                </Button>

                <Button href="https://gitlab.beyond-undefined.fr/lager" target="_blank">
                    Sources
                </Button>
            </Toolbar>
        </MainBar>
  )
}

export default JAppBar
