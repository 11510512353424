import React, { useContext } from 'react'
import AppContext from '../components/AppContext'
import { useQuery } from 'react-query'
import Upload from '../components/inventory/Upload'
import { apiArticlesC, Article } from '../utils/codecs'
import * as TE from 'fp-ts/TaskEither'
import * as E from 'fp-ts/Either'
import * as T from 'fp-ts/Task'
import { pipe } from 'fp-ts/function'
import { useNotifications } from '../utils/useNotifications'
import {
  Card,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material'
import Update from '../components/inventory/Update'

const InventoryView = () => {
  const { http } = useContext(AppContext)
  const { showError } = useNotifications()

  const { data, isSuccess } = useQuery<Article[]>('inventory',
    () => pipe(
      TE.tryCatch(() => http.get('/inventory'), E.toError),
      TE.map(res => res.data),
      TE.chainEitherKW(articles => apiArticlesC.decode(articles)),
      TE.mapLeft(err => {
        showError((err as Error).message)
        return err
      }),
      TE.getOrElse(() => T.of([] as Article[]))
    )()
  )

  return (
        <Card>
            <CardHeader
                title="Inventory"
                action={<Upload/>}
            />
            <CardContent>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell align="right">Stocks</TableCell>
                            <TableCell align="right">Update</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {isSuccess && data
                          ? data
                            .sort((art1, art2) => art2.art_id - art1.art_id)
                            .map((article: Article) => (
                                    <TableRow
                                        key={article.art_id}
                                    >
                                        <TableCell component="th" scope="row">
                                            {article.name}
                                        </TableCell>
                                        <TableCell align="right" component="th" scope="row">
                                            {article.stock}
                                        </TableCell>
                                        <TableCell align="right" component="th" scope="row">
                                            <Update article={article}/>
                                        </TableCell>
                                    </TableRow>
                            ))
                          : <></>
                        }
                    </TableBody>
                </Table>
            </CardContent>
        </Card>
  )
}

export default InventoryView
