import React, { useContext } from 'react'

import { Button } from '@mui/material'
import { useNotifications } from '../../utils/useNotifications'
import { Product, jsonProductsC } from '../../utils/codecs'
import { useMutation, useQueryClient } from 'react-query'
import AppContext from '../AppContext'
import handleJsonUpload from '../../utils/handleJsonUpload'

const ProductsUploadButton = () => {
  const queryClient = useQueryClient()
  const { http } = useContext(AppContext)
  const { showError } = useNotifications()

  const { mutate } = useMutation(
    (products: Product) => http.post('/products', products),
    {
      onError: err => {
        showError((err as Error).message)
      },
      onSettled: () => {
        queryClient.invalidateQueries('products')
      }
    }
  )

  const handleChange = handleJsonUpload(
    showError,
    jsonProductsC,
    ({ products }) => products.map(product => mutate(product))
  )

  return (
        <label htmlFor="upload-products">
            <input
                style={{ display: 'none' }}
                id="upload-products"
                name="upload-products"
                type="file"
                accept="application/json"
                onChange={handleChange}
            />

            <Button color="primary" variant="contained" component="span">
                Upload Products
            </Button>
        </label>
  )
}

export default ProductsUploadButton
