import * as t from 'io-ts'
import * as tt from 'io-ts-types'

export const jsonArticleC = t.type({
  art_id: tt.IntFromString,
  name: tt.NonEmptyString,
  stock: tt.IntFromString
})

export const jsonArticlesC = t.type({
  inventory: t.array(jsonArticleC)
})

export const apiArticlesC = t.array(t.type({
  art_id: t.Int,
  name: tt.NonEmptyString,
  stock: t.Int
}))

export type Article = t.TypeOf<typeof jsonArticleC>

export const jsonProductC = t.type(
  {
    name: tt.NonEmptyString,
    contain_articles: t.array(t.type({
      art_id: tt.IntFromString,
      amount_of: tt.IntFromString
    }))
  })

export const jsonProductsC = t.type({
  products: t.array(jsonProductC)
})

export const apiProductSummaryC = t.type({
  id: t.Int,
  name: tt.NonEmptyString,
  availability: t.Int
})

export const apiProductsSummaryC = t.array(apiProductSummaryC)

export type ProductSummary = t.TypeOf<typeof apiProductSummaryC>

export type Product = t.TypeOf<typeof jsonProductC>
