import React, { useContext } from 'react'
import AppContext from '../components/AppContext'
import { useNotifications } from '../utils/useNotifications'
import { useQuery } from 'react-query'
import { apiProductsSummaryC, ProductSummary } from '../utils/codecs'
import { pipe } from 'fp-ts/function'
import * as TE from 'fp-ts/TaskEither'
import * as E from 'fp-ts/Either'
import * as T from 'fp-ts/Task'
import Upload from '../components/products/Upload'
import {
  Card,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material'
import Sell from '../components/products/Sell'

const ProductsView = () => {
  const { http } = useContext(AppContext)
  const { showError } = useNotifications()

  const { data, isSuccess } = useQuery<ProductSummary[]>('products',
    () => pipe(
      TE.tryCatch(() => http.get('/products'), E.toError),
      TE.map(res => res.data),
      TE.chainEitherKW(articles => apiProductsSummaryC.decode(articles)),
      TE.mapLeft(err => {
        showError((err as Error).message)
        return err
      }),
      TE.getOrElse(() => T.of([] as ProductSummary[]))
    )()
  )

  return (
        <Card>
            <CardHeader
                title="Products"
                action={<Upload/>}
            />
            <CardContent>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell align="right">Available</TableCell>
                            <TableCell align="right">Sell</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {isSuccess && data
                          ? data
                            .sort((p1, p2) => p2.id - p1.id)
                            .map((product: ProductSummary) => (
                                    <TableRow
                                        key={product.id}
                                    >
                                        <TableCell component="th" scope="row">
                                            {product.name}
                                        </TableCell>
                                        <TableCell align="right" component="th" scope="row">
                                            {product.availability}
                                        </TableCell>
                                        <TableCell align="right" component="th" scope="row">
                                            <Sell product={product}/>
                                        </TableCell>
                                    </TableRow>
                            ))
                          : <></>
                        }
                    </TableBody>
                </Table>
            </CardContent>
        </Card>
  )
}

export default ProductsView
